import React, { useState } from 'react';

function App() {
    const [recipientName, setRecipientName] = useState('');
    const [projectNumber, setProjectNumber] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [calibrationDate, setCalibrationDate] = useState('');
    const [calibrationSerial, setCalibrationSerial] = useState('');
    const [files, setFiles] = useState(null);
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('recipientName', recipientName);
        formData.append('projectNumber', projectNumber);
        formData.append('customerAddress', customerAddress);
	formData.append('calibrationDate', calibrationDate);
	formData.append('calibrationSerial', calibrationSerial)
;
        if (files) {
            Array.from(files).forEach((file) => formData.append('testFiles', file));
        }

        try {
            const response = await fetch('/api/generate-report', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error generating report.');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Test_Report_${projectNumber}.pdf`;  // Correct file extension
            link.click();

            setStatus('Report generated successfully!');
        } catch (error) {
            console.error('Error:', error);
            setStatus('Error generating report.');
        }
    };

    return (
        <div className="container">
            <h1>Generate Test Report</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Recipient Name:</label>
                    <input
                        type="text"
                        value={recipientName}
                        onChange={(e) => setRecipientName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Project/Purchase Order Number:</label>
                    <input
                        type="text"
                        value={projectNumber}
                        onChange={(e) => setProjectNumber(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Customer Address:</label>
                    <textarea
                        value={customerAddress}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        required
                    />
                </div>                
		<div>
                    <label>Test Equipment Serial Number:</label>
                    <input
                        type="text"
                        value={calibrationSerial}
                        onChange={(e) => setCalibrationSerial(e.target.value)}
                        required
                    />
                </div>
		<div>
                    <label>Test Equipment Calibration Date:</label>
                    <input
                        type="text"
                        value={calibrationDate}
                        onChange={(e) => setCalibrationDate(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Upload Test Files:</label>
                    <input
                        type="file"
                        onChange={(e) => setFiles(e.target.files)}
                        multiple
                        required
                    />
                </div>
                <button type="submit">Generate Report</button>
            </form>
            <p>{status}</p>
        </div>
    );
}

export default App;
